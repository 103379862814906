import React, { lazy } from 'react';
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from 'components/layout'
const Terminos = lazy(() => import('sections/hero/Terminos.js'));
const BoxCol1Terminos = lazy(() => import('sections/hero/BoxCol1Terminos.js'));
const TerminosVasco = lazy(() => import('sections/services/TerminosVasco.js'));
const Terminos2Vasco = lazy(() => import('sections/services/Terminos2Vasco.js'));
const Llamenos = lazy(() => import('sections/hero/Llamenos.js'));
const ContactCreative = lazy(() => import('sections/contact/ContactCreative.js'));
const Footer = lazy(() => import('sections/services/Footer.js'));

class Index extends React.Component {

  render() {
    
    
    return (
      <div>
        <Helmet>
          <title>Términos y Condiciones del Servicio de VASCO Solutions S.A.S</title>
          <meta name="description" content="Términos y Condiciones del Servicio de VASCO Solutions S.A.S. Descubre nuestras políticas y acuerdos para garantizar una experiencia transparente y segura." />
        </Helmet>
        <Layout>
          <Terminos />          
          <TerminosVasco />
          <BoxCol1Terminos />
          <Terminos2Vasco />          
          <Llamenos />          
          <ContactCreative />
          <Footer />
        </Layout>
      </div>
    )
  }
}

export default Index

export const creativeVideoTwoQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
      }
    }
  }
`